// TODO: LB - use correct import syntax without disabling linter
// eslint-disable-next-line @typescript-eslint/consistent-type-imports
type FloatingUiDom = typeof import("@floating-ui/dom");

import type { Middleware, Side } from "@floating-ui/dom";

const tooltipToggleEls = document.querySelectorAll(".js-tooltip-toggle");

interface Config {
	computePosition: FloatingUiDom["computePosition"];
	middlewares: Array<() => Middleware>;
}

enum TooltipState {
	Visible = "visible",
	Hidden = "hidden",
}
enum ExternalClickAction {
	Hide = "hide",
	Ignore = "ignore",
}
enum ClickEvent {
	DocumentClick = "documentclick",
}

function tooltipFactory(toggleEl: HTMLElement, config: Config) {
	const tooltipEl = document.querySelector(
		toggleEl.dataset.target || "",
	) as HTMLElement;
	const events: string[] = (toggleEl.dataset.events || "click")
		.split(",")
		.map((eventName) => eventName.trim());
	const state = {
		tooltipEl,
		value: TooltipState.Hidden,
	};

	function init() {
		const initialState =
			state.tooltipEl &&
			Object.values(TooltipState).indexOf(
				state.tooltipEl.dataset.state as TooltipState,
			) > -1
				? (state.tooltipEl.dataset.state as TooltipState)
				: TooltipState.Hidden;

		setState(initialState);
		initializeEvents();
	}

	function setState(value: TooltipState): TooltipState {
		state.value = value;

		return state.value;
	}

	function getState(): TooltipState {
		return state.value;
	}

	function updateAttributes() {
		const ariaValue = `${getState() === TooltipState.Hidden}`;

		state.tooltipEl.setAttribute("data-state", state.value);
		state.tooltipEl.setAttribute("aria-hidden", ariaValue);
	}

	async function update() {
		updateAttributes();

		const dataset = toggleEl.dataset;
		const side = (dataset.side || "right") as Side;
		const { computePosition } = config;
		const { x, y } = await computePosition(toggleEl, state.tooltipEl, {
			middleware: config.middlewares.map((middleware) => middleware()),
			placement: side,
		});

		Object.assign(state.tooltipEl.style, {
			left: `${x}px`,
			top: `${y}px`,
		});
	}

	async function toggleVisibility() {
		const newState =
			getState() === TooltipState.Hidden
				? TooltipState.Visible
				: TooltipState.Hidden;

		setState(newState);

		await update();
	}

	async function handleExternalClick(event: Event) {
		const customEvent = event as CustomEvent<PointerEvent>;
		const clickedEl = customEvent.detail.target as HTMLElement;
		const shouldHide = [
			// hide on external click
			state.tooltipEl.dataset.externalClickAction === ExternalClickAction.Hide,
			// is visible
			getState() === TooltipState.Visible,
			// is not the tooltip, or a child of the tooltip
			!(
				clickedEl === tooltipEl ||
				Boolean(clickedEl.closest(`#${state.tooltipEl.id}`))
			),
			// is not the toggle, or a child of the toggle
			!(
				clickedEl === toggleEl ||
				Boolean(clickedEl.closest(`[data-target='#${state.tooltipEl.id}']`))
			),
		].every(Boolean);

		if (shouldHide) {
			setState(TooltipState.Hidden);

			await update();
		}
	}

	function initializeEvents() {
		events.forEach((eventName) => {
			toggleEl.addEventListener(eventName, toggleVisibility);
		});

		document.addEventListener(ClickEvent.DocumentClick, handleExternalClick);
	}

	function destroy() {
		events.forEach((eventName) => {
			toggleEl.removeEventListener(eventName, toggleVisibility);
		});

		document.removeEventListener(ClickEvent.DocumentClick, handleExternalClick);
	}

	return { init, destroy };
}

async function initTooltips(toggleEls: NodeList, config: Config) {
	Array.from(toggleEls)
		.map((toggleEl) => tooltipFactory(toggleEl as HTMLElement, config))
		.forEach((factory) => (factory ? factory.init() : null));
}

function handleExternalClicks() {
	document.addEventListener("click", (event) => {
		const clickEvent = new CustomEvent(ClickEvent.DocumentClick, {
			detail: event,
		});

		document.dispatchEvent(clickEvent);
	});
}

if (tooltipToggleEls.length > 0) {
	(async () => {
		/**
		 * Import asynchronously, and only import the exact modules we need
		 */
		const { computePosition, shift, offset, autoPlacement } = await import(
			"@floating-ui/dom"
		);

		await initTooltips(tooltipToggleEls, {
			computePosition,
			middlewares: [
				// order matters!
				() => offset(6),
				() => shift({ padding: 5 }),
				autoPlacement,
			],
		});

		handleExternalClicks();
	})();
}
