enum State {
	NotLoaded = "not-loaded",
	Loaded = "loaded",
}

let state = State.NotLoaded;
const loadedEvent = new CustomEvent("document:imageannotatorloader:success");

async function loadImageAnnotator() {
	if (state === State.NotLoaded) {
		await import("./image-annotator");
		state = State.Loaded;
	}

	document.dispatchEvent(loadedEvent);
}

document.addEventListener("imageannotatorloader:fetch", loadImageAnnotator);
